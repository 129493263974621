<template>
  <div class="text-center text-policy-terms">
    <span>
      By signing in you agree to our
    </span>
    <span class="red-color cursor-pointer" @click="() => {this.$router.push({path:'/privacy'})}">Privacy Policy</span>
    <span> and </span>
    <span class="red-color cursor-pointer" @click="() => {this.$router.push({path:'/terms'})}">Terms of Service</span>

    <div class="mt-3">
      <a class="red-color cursor-pointer" @click="() => {this.$router.push({path:'/'})}">Go home</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "privacy-terms"
}
</script>

<style scoped>

</style>